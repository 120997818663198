<template>
    <div class="component_container">
        <footer class="main-footer">
            <!-- To the right -->
            <div class="float-right d-none d-sm-inline">
                Anything you want
            </div>
            <!-- Default to the left -->
            <strong>Copyright &copy; 2014-2019 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights reserved.
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
               
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{
        }
    }
</script>

<style>

</style>